module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js","blog":"/home/ubuntu/flomesh.io-main/src/layouts/Fixed/BlogMdx.js"},"remarkPlugins":[null],"gatsbyRemarkPlugins":[],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/ubuntu/flomesh.io-main","commonmark":false},
    }]

import SvgStreams from "../../../../pipy/docs/intro/streams.svg";
import SvgStreamInput from "../../../../pipy/docs/intro/stream-input.svg";
import SvgStreamOutput from "../../../../pipy/docs/intro/stream-output.svg";
import SvgStreamTransform from "../../../../pipy/docs/intro/stream-transform.svg";
import SvgPipelineFilters from "../../../../pipy/docs/intro/pipeline-filters.svg";
import SvgSubPipeline from "../../../../pipy/docs/intro/sub-pipeline.svg";
import SvgModulePipelineContext from "../../../../pipy/docs/intro/module-pipeline-context.svg";
import * as React from 'react';
export default {
  SvgStreams,
  SvgStreamInput,
  SvgStreamOutput,
  SvgStreamTransform,
  SvgPipelineFilters,
  SvgSubPipeline,
  SvgModulePipelineContext,
  React
};
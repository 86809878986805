import FlagIcon from '@material-ui/icons/FlagSharp';
import Layer4Proxy from "../../../../pipy/docs/tutorial/layer-4-proxy.svg";
import Layer7Proxy from "../../../../pipy/docs/tutorial/layer-7-proxy.svg";
import DemuxHTTP from "../../../../pipy/docs/tutorial/demux-http.svg";
import MuxHTTP from "../../../../pipy/docs/tutorial/mux-http.svg";
import SvgProxyPipelines from "../../../../pipy/docs/tutorial/proxy-pipelines.svg";
import * as React from 'react';
export default {
  FlagIcon,
  Layer4Proxy,
  Layer7Proxy,
  DemuxHTTP,
  MuxHTTP,
  SvgProxyPipelines,
  React
};
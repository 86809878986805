import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Page from '../../src/components/Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';
import './layout.css';
// import * as gtm from 'gtagmanager'
import './i18n';

export default function TopLayout(props) {
	// try{
	// 	gtm.standardDataLayerInit() // will add a standard dataLayer to the window object
	// 	window.dataLayer.push({
	// 		"js": new Date(),
	// 		"config": 'G-BRLNHWRLHQ'
	// 	})
	// 	let myGTM = new gtm.GTagManager('GTM-BRLNHWRLHQ');
		 
	// 	myGTM.start() // starts the GTM instance
	// 	myGTM.pushToDataLayer({ "event": "visit" }) 
	// }catch(e){
	// }
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="shortcut icon"
          href={'/img/favicon.ico'}
        />
				
				<meta name="keywords" content="reverse-proxy,api-gateway,proxy,ingress,gateway,http-proxy,load-balancer,socks-proxy,forward-proxy,service-mesh,sidecar,programmable-proxy,network-proxy,programmable-network,egress,sidecar-proxy,ingress-gateway,egress-gateway,software-loadbalancer,cloud native,kubernetes,microservices,load balancing,observability,data plane,traffic management,high performance"/>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Pipy - A programmable network proxy for the cloud, edge and IoT</title>
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Pipy - A programmable network proxy for the cloud, edge and IoT."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="/img/screen-img.svg"
        />
        <meta
          property="og:title"
          content="Pipy"
        />
        <meta
          property="og:description"
          content="A programmable network proxy for the cloud, edge and IoT."
        />
        <meta
          property="og:url"
          content="https://flomesh.io/"
        />
				<script src={'/js/gtag.js'}/>
				<script>{`
				window.dataLayer = window.dataLayer || [];
				  function gtag(){dataLayer.push(arguments);}
				  gtag('js', new Date());
				
				  gtag('config', 'G-BRLNHWRLHQ');
				`}</script>
			</Helmet>
      <Page>
        {props.children}
      </Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};

import PlusIcon from '@material-ui/icons/AddSharp';
import FlagIcon from '@material-ui/icons/FlagSharp';
import SaveIcon from '@material-ui/icons/SaveSharp';
import PlayIcon from '@material-ui/icons/PlayArrowSharp';
import StopIcon from '@material-ui/icons/StopSharp';
import SvgHelloPipeline from "../../../../pipy/docs/tutorial/hello-pipeline.svg";
import * as React from 'react';
export default {
  PlusIcon,
  FlagIcon,
  SaveIcon,
  PlayIcon,
  StopIcon,
  SvgHelloPipeline,
  React
};